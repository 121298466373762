export const PRINT1 = 'PRINT1'
export const PRINT2 = 'PRINT2'

export const PRINT_ARTICLE_TYPES = [
  {
    id: PRINT1,
    title: PRINT1
  },
  {
    id: PRINT2,
    title: PRINT2
  }
]
