<template>
  <section>
    <label v-if="label.length > 0">{{ label }}</label>
    <div>
      <template v-for="(option, index) in options">
        <label :key="`item-${index}`" class="btn btn-secondary mr-1" :for="option.id" :class="{ 'active': value === option.title }">
          <input
            type="radio"
            @click="$emit('input', $event.target.value)"
            :value="option.title"
            :id="option.id"
            :name="name"
            :data-test="dataAttr"
          >
          {{ option.title }}
        </label>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Choice',
  props: {
    value: null,
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default () {
        return []
      }
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
    value () {
      console.log('val of ' + this.name + ' is ' + this.value)
    }
  }
}
</script>
