export const SOURCE_AUTHOR = 'author'
export const SOURCE_AGENCY = 'agency'

export const PRINT_ARTICLE_SOURCES = [
  {
    id: SOURCE_AUTHOR,
    title: SOURCE_AUTHOR
  },
  {
    id: SOURCE_AGENCY,
    title: SOURCE_AGENCY
  }
]
