export const QUALITY_A = 'A'
export const QUALITY_B = 'B'
export const QUALITY_C = 'C'

export const PRINT_ARTICLE_QUALITIES = [
  {
    id: QUALITY_A,
    title: QUALITY_A
  },
  {
    id: QUALITY_B,
    title: QUALITY_B
  },
  {
    id: QUALITY_C,
    title: QUALITY_C
  }
]
